import { MeetingAppSelection, TactiqMessageType, logger } from '@tactiq/model';
import { User, getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import {
  LandingFormExtensionWrapperClasses,
  LandingPageFlavorTextClasses,
} from '../../app/Common/SignInComponents';
import { Button } from '../../components/buttons';
import { Switch } from '../../components/buttons/Switch';
import {
  trackPrivacyPolicyViewed,
  trackTermsAndConditionsViewed,
  trackWebEvent,
  trackWebPage,
} from '../../helpers/analytics';
import { sendMessage } from '../../helpers/extension';
import { cx } from '../../helpers/utils';
import image_equifax from '../../img/landing_equifax.svg';
import image_flipkart from '../../img/landing_flipkart.svg';
import image_fossil from '../../img/landing_fossil.svg';
import image_mulesoft from '../../img/landing_mulesoft.svg';
import image_netflix from '../../img/landing_netflix.svg';
import image_rh from '../../img/landing_rh.svg';
import image_riot from '../../img/landing_riot.svg';
import image_sfdc from '../../img/landing_sf.svg';
import image_miro from '../../img/landing_miro.svg';
import image_spotify from '../../img/landing_spotify.svg';
import image_square from '../../img/landing_square.svg';
import image_time from '../../img/landing_time.svg';
import image_twitch from '../../img/landing_twitch.svg';
import image_vice from '../../img/landing_vice.svg';
import image_woolworths from '../../img/landing_wollworth.svg';
import image_yelp from '../../img/landing_yelp.svg';
import tactiqLogo from '../../img/logo-128.png';
import { SignInBlock } from './SignInBlock';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../components/Link';

const LandingPageTitleClasses = 'mb-0 font-medium text-2xl text-slate-700';

const welcomeWrapperClasses =
  'w-full relative min-h-screen bg-[#052a57] text-white flex flex-col justify-center items-center';

const welcomeFormClasses = 'max-w-[31rem] py-5 px-[10px] m-auto text-left';

const signUpBlockRightClasses =
  'w-full relative min-h-screen bg-[#f1f3f9] text-[#052a57] flex flex-col justify-center items-center';

type SpecificLogoProps = 'SalesForce' | 'Woolworths' | 'Yelp';

const imageWrapClasses = 'max-w-[480px] p-0 m-auto flex flex-col';

const imageRowClasses =
  'max-w-[480px] py-[10px] w-full m-auto flex flex-row justify-between';

const logoBaseClasses = 'bg-no-repeat bg-position-center m-auto';

const LogoImage: React.FC<{
  image: string;
  specificLogo?: SpecificLogoProps;
}> = ({ image, specificLogo }) => {
  let logoSizes = '';
  switch (specificLogo) {
    case 'SalesForce':
      logoSizes = 'w-[72px] h-[44px] bg-size-[54px 45px]';
      break;
    case 'Woolworths':
      logoSizes = 'w-[55px] h-[46px] bg-size-[55px 46px]';
      break;
    case 'Yelp':
      logoSizes = 'w-[55px] h-[46px] bg-size-[55px 46px]';
      break;
    default:
      logoSizes = 'w-[72px] h-[46px] bg-size-[72px 20px]';
      break;
  }
  return (
    <img
      className={cx(logoBaseClasses, logoSizes)}
      src={image}
      alt="company logo"
    />
  );
};

export function getMasFromString(mas: string | null): MeetingAppSelection {
  return {
    zoom: mas?.charAt(0) === '1',
    msteams: mas?.charAt(1) === '1',
    webex: mas?.charAt(2) === '1',
    meet: mas?.charAt(3) === '1',
    other: mas?.charAt(4) === '1',
  };
}

function createMasString(masObject: MeetingAppSelection): string {
  const items = [];
  items.push(masObject.zoom ? '1' : '0');
  items.push(masObject.msteams ? '1' : '0');
  items.push(masObject.webex ? '1' : '0');
  items.push(masObject.meet ? '1' : '0');
  items.push(masObject.other ? '1' : '0');
  return items.join('');
}

export const WelcomeForNewInstalls: React.FC<{
  mas: string | null;
  doNotAutoRedirect?: boolean;
  oauthState: string;
}> = ({ mas, doNotAutoRedirect, oauthState }) => {
  const onAuthStateUpdate = useCallback(
    (u: User | null) => {
      if (u?.uid) {
        /**
         * Nothing to do on this page; may have been opened by a buggy
         * Chrome onInstall event, either close this tab or redirect the user to a
         * better place
         */
        if (!doNotAutoRedirect) {
          trackWebEvent(
            'Auto-redirecting logged in user from the welcome page'
          );
          window.location.href = '/';
        }
      } else {
        trackWebPage('Welcome');
      }
    },
    [doNotAutoRedirect]
  );

  useEffect(() => {
    onAuthStateChanged(getAuth(), onAuthStateUpdate);
  }, [onAuthStateUpdate]);

  const [isSignInInProgress, setIsSignInInProgress] = useState(false);

  const [meetEnabled, setMeetEnabled] = useState(false);
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [msteamsEnabled, setMsteamsEnabled] = useState(false);
  const [webexEnabled, setWebexEnabled] = useState(false);
  const [otherEnabled, setOtherEnabled] = useState(false);

  const askForPermissions = useCallback((masObject: MeetingAppSelection) => {
    sendMessage({
      type: TactiqMessageType.openOptionsPage,
      path: `/Permissions?s=${createMasString(masObject)}`,
    }).catch((err) => {
      logger.error(err);
    });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flexBasis: '50%' }}>
        <div className={welcomeWrapperClasses}>
          <div className={LandingFormExtensionWrapperClasses}>
            <div className={welcomeFormClasses}>
              <div className="rounded-lg bg-white p-5 text-black">
                <div className="flex flex-col gap-4">
                  {isSignInInProgress ? null : (
                    <>
                      <img
                        src={tactiqLogo}
                        height={54}
                        width={54}
                        alt="Tactiq logo"
                      />
                      {mas && (
                        <div className="text-xl font-semibold">
                          Finish creating your account
                        </div>
                      )}
                    </>
                  )}
                  {!mas ? (
                    <>
                      <div className="text-xl font-semibold">
                        Choose your meeting apps
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center">
                          <div className="flex-1">Google Meet</div>
                          <Switch
                            isOn={meetEnabled}
                            onClick={(e, checked) => {
                              trackWebEvent(
                                'Toggled Google Meet on welcome page',
                                {
                                  status: checked,
                                }
                              );
                              setMeetEnabled(checked);
                            }}
                          />
                        </div>
                        <div className="flex items-center">
                          <div className="flex-1">Zoom</div>
                          <Switch
                            isOn={zoomEnabled}
                            onClick={(e, checked) => {
                              trackWebEvent('Toggled Zoom on welcome page', {
                                status: checked,
                              });
                              setZoomEnabled(checked);
                            }}
                          />
                        </div>
                        <div className="flex items-center">
                          <div className="flex-1">Microsoft Teams</div>
                          <Switch
                            isOn={msteamsEnabled}
                            onClick={(e, checked) => {
                              trackWebEvent('Toggled MSTeams on welcome page', {
                                status: checked,
                              });
                              setMsteamsEnabled(checked);
                            }}
                          />
                        </div>
                        <div className="flex items-center">
                          <div className="flex-1">Webex</div>
                          <Switch
                            isOn={webexEnabled}
                            onClick={(e, checked) => {
                              trackWebEvent('Toggled Webex on welcome page', {
                                status: checked,
                              });
                              setWebexEnabled(checked);
                            }}
                          />
                        </div>
                        <div className="flex items-center">
                          <div className="flex-1">Other</div>
                          <Switch
                            isOn={otherEnabled}
                            onClick={(e, checked) => {
                              trackWebEvent('Toggled Other on welcome page', {
                                status: checked,
                              });
                              setOtherEnabled(checked);
                            }}
                          />
                        </div>
                      </div>
                      <Button
                        variant="filled"
                        fullWidth
                        onClick={() => {
                          trackWebEvent('Ask for Permissions on welcome page', {
                            meetEnabled,
                            zoomEnabled,
                            msteamsEnabled,
                            webexEnabled,
                            otherEnabled,
                          });
                          askForPermissions({
                            meet: meetEnabled,
                            zoom: zoomEnabled,
                            msteams: msteamsEnabled,
                            webex: webexEnabled,
                            other: otherEnabled,
                          });
                        }}
                      >
                        Continue
                      </Button>
                    </>
                  ) : (
                    <SignInBlock
                      oauthState={oauthState}
                      where={'welcome'}
                      setIsSignInInProgress={(isIt) =>
                        setIsSignInInProgress(isIt)
                      }
                    />
                  )}
                </div>
                <div className="mt-5 text-xs text-slate-400">
                  <FormattedMessage
                    defaultMessage="By signing up, you agree to Tactiq's {terms} and {privacy}"
                    id="ESckU/"
                    values={{
                      br: <br />,
                      terms: (
                        <Link
                          blue
                          onClick={() =>
                            trackTermsAndConditionsViewed(undefined)
                          }
                          to="https://tactiq.io/privacy/terms"
                          target="_blank"
                        >
                          <FormattedMessage
                            defaultMessage="Terms & Conditions"
                            id="arPp4e"
                          />
                        </Link>
                      ),
                      privacy: (
                        <Link
                          blue
                          onClick={() => trackPrivacyPolicyViewed(undefined)}
                          target="_blank"
                          to="https://tactiq.io/privacy/privacy"
                        >
                          <FormattedMessage
                            defaultMessage="Privacy Policy"
                            id="vx0nkZ"
                          />
                        </Link>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ flexBasis: '50%' }}>
        <div className={signUpBlockRightClasses}>
          <div className={LandingFormExtensionWrapperClasses}>
            <p className={LandingPageTitleClasses}>JOIN OVER 250,000 PEOPLE</p>
            <p className={LandingPageTitleClasses}>ACROSS 8,000+ COMPANIES</p>
            <p className={LandingPageFlavorTextClasses}>
              Some of the largest companies in the world are using Tactiq
            </p>
            <div className={imageWrapClasses}>
              <div className={imageRowClasses}>
                <LogoImage image={image_sfdc} specificLogo="SalesForce" />
                <LogoImage image={image_netflix} />
                <LogoImage image={image_miro} />
                <LogoImage image={image_time} />
              </div>
              <div className={imageRowClasses}>
                <LogoImage image={image_spotify} />
                <LogoImage image={image_equifax} />
                <LogoImage image={image_riot} />
                <LogoImage image={image_woolworths} specificLogo="Woolworths" />
              </div>
              <div className={imageRowClasses}>
                <LogoImage image={image_flipkart} />
                <LogoImage image={image_vice} />
                <LogoImage image={image_twitch} />
                <LogoImage image={image_mulesoft} />
              </div>
              <div className={imageRowClasses}>
                <LogoImage image={image_square} />
                <LogoImage image={image_yelp} specificLogo="Yelp" />
                <LogoImage image={image_rh} />
                <LogoImage image={image_fossil} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
